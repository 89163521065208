import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { HttpResponse } from '../../types/generalTypes'

export interface OfrForm {
  id: string
  title: string
  status: string
  createdDate: string
}

export interface OfrFormResponse extends HttpResponse {
  data: OfrForm[]
}

export interface OfrState {
  ofrFormList?: OfrFormResponse
  selectedForm?: OfrForm
  loading: boolean
}

const initialState: OfrState = {
  loading: false,
  selectedForm: undefined,
}

const ofrFormSlice = createSlice({
  name: 'ofr',
  initialState,
  reducers: {
    setOfrFormList: (state, action: PayloadAction<OfrFormResponse>) => {
      state.ofrFormList = action.payload
    },

    setOfrFormLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setSelectedOfrForm: (state, action: PayloadAction<OfrForm>) => {
      state.selectedForm = action.payload
    },
    clearOfrState: () => initialState,
  },
})

export const {
  setOfrFormList,
  setOfrFormLoading,
  setSelectedOfrForm,
  clearOfrState,
} = ofrFormSlice.actions

export const selectedForm = (state: RootState) => state.ofrForm.selectedForm

const ofrFormReducer = ofrFormSlice.reducer
export default ofrFormReducer
