//URL
export interface UrlList {
  Audit_TRAILS: string
  CURRENT_USER: string
  DATA_SOURCE_TYPES: string
  DATA_SOURCES: string
  DATA_SOURCES_DEFAULT: string
  DATA_STORES: string
  EGNYTE_FOLDERS: string
  FORGOT_PASSWORD_INVITE: string
  FORGOT_PASSWORD_VALIDATE: string
  INVITE_USER: string
  LITERATURE_REVIEWS: string
  LOGIN: string
  LOGOUT: string
  OFR: string
  REGISTER_USER: string
  ROLES: string
  SEARCH: string
  SHAREPOINT_SITES: string
  LOGIN_SSO_SAML: string
  USERS: string
  WORKSPACE_TYPES: string
  WORKSPACES: string
}

const basePath = '/api/'
const baseUserPath = `${basePath}users/`

export const urlList: UrlList = {
  Audit_TRAILS: `${basePath}auditTrail`,
  CURRENT_USER: `${baseUserPath}current`,
  DATA_SOURCE_TYPES: `${basePath}dataSourceTypes`,
  DATA_SOURCES: `${basePath}dataSources`,
  DATA_SOURCES_DEFAULT: `${basePath}dataSources/default`,
  DATA_STORES: `${basePath}dataStores`,
  EGNYTE_FOLDERS: `${basePath}egnyteFolders/validate`,
  FORGOT_PASSWORD_INVITE: `${baseUserPath}password/invite`,
  FORGOT_PASSWORD_VALIDATE: `${baseUserPath}password/inviteToken`,
  INVITE_USER: `${baseUserPath}register/invite`,
  LITERATURE_REVIEWS: `${basePath}literatureReviews`,
  LOGIN: `${baseUserPath}login`,
  LOGOUT: `${baseUserPath}logout`,
  REGISTER_USER: `${baseUserPath}register`,
  ROLES: `${basePath}roles`,
  SEARCH: `${basePath}search`,
  SHAREPOINT_SITES: `${basePath}sharepointSites`,
  LOGIN_SSO_SAML: `${baseUserPath}login/sso/saml`,
  USERS: baseUserPath,
  WORKSPACE_TYPES: `${basePath}workspaceTypes`,
  WORKSPACES: `${basePath}workspaces`,
  OFR: `${basePath}ofr`,
}
