import { algorithmRunStatuses } from '../../constants/constants'
import { OfrFormResponse } from '../../store/reducers/ofrFormReducer'

const mockOfrFormList: OfrFormResponse = {
  data: [
    {
      id: '1',
      title: 'test title',
      status: algorithmRunStatuses.READY,
      createdDate: '2020-02-02',
    },
    {
      id: '2',
      title: 'test title 2',
      status: algorithmRunStatuses.ONGOING_AI,
      createdDate: '2020-02-02',
    },
    {
      id: '3',
      title: 'test title 3',
      status: algorithmRunStatuses.ONGOING_INGESTION,
      createdDate: '2020-02-02',
    },
    {
      id: '4',
      title: 'test title 4',
      status: algorithmRunStatuses.COMPLETE,
      createdDate: '2020-02-02',
    },
    {
      id: '5',
      title: 'test title 5',
      status: algorithmRunStatuses.FAILED,
      createdDate: '2020-02-02',
    },
    {
      id: '6',
      title: 'test title 6',
      status: algorithmRunStatuses.CANCELLED,
      createdDate: '2020-02-02',
    },
  ],
}

export default mockOfrFormList
